<template>
  <div style="background-color: white; height: 100%; width: 100%; position: fixed">
    <div style="display: flex; padding: 35px 28px; justify-content: space-between">
      <span class="welcome">欢迎注册<br>裕登小馋猫(骑手端)</span>
      <van-image :src="require('@/assets/login/denglu_logo_img.png')" width="70" height="67" />
    </div>
    <van-form @submit="register" v-model="formData">
      <van-field
          style="margin-top: 10px; color: #999999; display: block"
          :label-class="'label-class'"
          v-model="formData.phoneNumber"
          type="text"
          name="mobileNumber"
          label="手机号"
          placeholder="请输入手机号"
          :rules="[{ required: true}]"
      />
      <van-field
          style="margin-top: 10px; color: #999999; display: block"
          :label-class="'label-class'"
          v-model="formData.smsCode"
          type="text"
          name="smsCode"
          label="验证码"
          placeholder="请输入验证码"
          :rules="[{ required: true}]"
      >
        <template #button>
          <van-button size="small" color="#FFBD04" plain style="border-radius: 4px" @click="sendSmsCode">获取验证码</van-button>
        </template>
      </van-field>
      <van-field
          style="margin-top: 10px; color: #999999; display: block"
          :label-class="'label-class'"
          v-model="formData.password"
          :type="passwordFieldType"
          name="password"
          label="密码"
          placeholder="请输入密码"
          :rules="[{ required: true }]"
      >
        <template #right-icon>
          <van-image @click="togglePwIcon" width="15" height="15" :src="passwordFieldType === 'password' ? require('@/assets/login/dlu_bkj_icon.png') : require('@/assets/login/dlu_kejian_icon.png')" />
        </template>
      </van-field>
      <van-field
          style="margin-top: 10px; color: #999999; display: block"
          :label-class="'label-class'"
          v-model="formData.confirmPassword"
          :type="passwordFieldType2"
          name="confirmPassword"
          label="确认密码"
          placeholder="请再次输入密码，两次密码需一致"
          :rules="[{ required: true }]"
      >
        <template #right-icon>
          <van-image @click="togglePwIcon2" width="15" height="15" :src="passwordFieldType2 === 'password' ? require('@/assets/login/dlu_bkj_icon.png') : require('@/assets/login/dlu_kejian_icon.png')" />
        </template>
      </van-field>
      <div style="margin: 40px 20px 28px 28px;">
        <van-button round block type="info" color="#FFBD04" native-type="submit" style="height: 55px; font-size: 18px">
          注册
        </van-button>
      </div>
      <div style="text-align: center; font-size: 14px; font-weight: 500; color: #333333" @click="backTo('/login')">已经有账号了？ <span style="color:#2394FD;">去登录</span></div>
    </van-form>
  </div>
</template>

<script>
import Client from "@/api";
export default {
  name: "Register",
  data: function () {
    return {
      formData: {
        phone: null,
        smsCode: null,
        password: null,
        confirmPassword: null,
        phoneNumber: null
      },
      passwordFieldType: 'password',
      passwordFieldType2: 'password'
    }
  },
  methods: {
    sendSmsCode() {
      if (this.formData.phoneNumber.length === 11) {
        this.$toast.loading()
        Client.get('courier/smsCode', {params: {phone: this.formData.phoneNumber}}).then(res => {
          this.$toast.clear()
          if (res.data.state === 'ok') {
            this.$toast.success({message: '验证码发送成功'})
          } else {
            this.$toast.fail({message: res.data.msg})
          }
        }).catch(err => {
          this.$toast.clear()
          this.$toast.fail(err.response.data.msg)
        })
      } else {
        this.$toast.fail({message: '请输入正确的中国地区手机号码'})
      }
    },
    register(values) {
      if (values.password !== values.confirmPassword) {
        this.$toast.fail('密码不一致，请重新输入')
        return
      }
      this.$toast.loading()
      values.username = values.mobileNumber
      Client.post('courier/register', values).then(res => {
        this.$toast.clear()
        if (res.data.state === 'ok') {
          this.$toast.success({message: '注册成功', onClose: () => { this.backTo('/index')} })
        } else {
          this.$toast.fail({message: res.data.msg})
        }
      }).catch(err => {
        this.$toast.clear()
        this.$toast.fail(err.response.data.msg)
      })
    },
    togglePwIcon() {
      if (this.passwordFieldType === 'password') {
        this.passwordFieldType = 'text'
      } else {
        this.passwordFieldType = 'password'
      }
    },
    togglePwIcon2() {
      if (this.passwordFieldType2 === 'password') {
        this.passwordFieldType2 = 'text'
      } else {
        this.passwordFieldType2 = 'password'
      }
    }
  }
}
</script>

<style scoped>
.welcome {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  line-height: 35px;
}
/deep/ .label-class {
  margin-bottom: 22px;
  color: #999999;
  font-weight: 500;
  font-size: 13px;
}
/deep/ .van-field__control {
  font-size: 17px;
  font-weight: 500;
}
/deep/ .van-cell::after {
  left: 28px;
  right: 28px;
}
/deep/ .van-cell {
  padding: 10px 28px;
}
.forgot-password {
  right: 28px;
  position: absolute;
  margin-top: 15px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #FFBD04;
  line-height: 9px;
}
</style>
